export async function processUserFormData(profile) {
    if (profile && profile.email && profile.consent) {
        try {
            let data = profile;
            data.consent = profile.consent ? 1 : 0; //format correctly
            let leadGenResponse = await updateUserFormData(profile);
            let leadJson = await leadGenResponse.json();
            if (leadJson.result && leadJson.result.length > 0) {
                // console.table(leadGenResponse);
                return leadJson.result[0].id; //comment to test form
            }
            console.log("Profile updated");
            return "";
        }
        catch (error) {
            console.log("Access Token error", error.message);
        }
    }
    else {
        console.debug("EMail not found in profile");
    }
    return null;
}
const API_ENDPOINT_DOMAIN = "https://blackmores-quiz-api-uat.vercel.app";
export const updateUserFormData = async (profile) => await fetch(
// "https://blackmores-quiz-api.vercel.app/api/updateLead",
API_ENDPOINT_DOMAIN + "/api/updateLead", {
    method: "POST",
    body: JSON.stringify(profile),
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json; charset=UTF-8",
    },
});
