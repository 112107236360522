<script lang="ts">
  import { onMount } from "svelte";
  import { inview } from "svelte-inview";
  import { fade } from "svelte/transition";
  import {
    getInfoColors,
    getTitleColors,
    isSectionUnlocked,
    Section,
    type Question,
  } from "../../interfaces/interfaces";
  import {
    answers,
    profile,
    questions,
    scores,
  } from "../../stores/questions.store";
  import SubSection from "../subsections/SubSection.svelte";
  // import { LottiePlayer } from "@lottiefiles/svelte-lottie-player";
  import { scrollRef } from "svelte-scrolling";
  import {
    getRecommendableProducts,
    getRecommendationBlurb,
    scoringScaleFactor,
  } from "../../interfaces/lookups";
  import { processUserFormData } from "../../lib/marketo";
  import Lottie from "../animation/Lottie.svelte";
  import ScrollScaledImage from "../animation/ScrollScaledImage.svelte";
  import StopMoCarousel from "../carousel/StopMoCarousel.svelte";
  import MediaQuery from "../MediaQuery.svelte";

  export let question: Question;
  export let y;
  export let sectionName: Section; // shows up in the question cookie

  $: stopMoItems = [];
  // const scaleFactor = 1;

  $: infoInView = false;
  $: questionInView = false;

  let titleBgClass = "text-bm-text-orange";
  let titleTextClass = "text-bm-text-orange";
  let infoBgClass = "text-bm-text-orange";
  let infoTextClass = "text-bm-text-orange";
  let questionHoverTextClass = "text-bm-text-orange";
  let baseColor = "#F1801F";

  onMount(() => {
    stopMoItems = question.stopMoUrls ?? [];
    let titleColors = getTitleColors(question);
    let infoColors = getInfoColors(question);

    titleBgClass = titleColors.titleBgClass;
    titleTextClass = titleColors.titleTextClass;
    infoBgClass = infoColors.infoBgClass;
    infoTextClass = infoColors.infoTextClass;
    questionHoverTextClass = infoColors.questionHoverTextClass;
    baseColor = titleColors.baseColor;
  });

  let selectionPromise;
  function triggerSelection(value: number, score: number) {
    selectionPromise = selectOption(value, score);
  }
  async function selectOption(value: number, score: number) {
    let currAnswers = $answers;
    currAnswers[sectionName] = value;
    $answers = currAnswers;

    let currScores = $scores;
    currScores[sectionName] = score ?? 0;
    $scores = currScores;

    addSectionToProfile(sectionName);

    // if (getNextSection() == Section.Final) {
    // The fish question has been answered. Now we need to process the form and update lead score.
    console.log("Updating lead");
    let tmpProfile = $profile;
    tmpProfile["answers"] = {};
    for (var k in currAnswers) tmpProfile["answers"][k] = currAnswers[k];

    tmpProfile["isPregnant"] = getPregnancyStatusFromChoice(); // $answers.pregnancy == 23;
    tmpProfile["hasPet"] =
      $answers.pets == 17 || $answers.pets == 18 || $answers.pets == 19;
    tmpProfile["whatPet"] = "";
    if ($answers.pets == 17) {
      tmpProfile["whatPet"] = "dog";
    } else if ($answers.pets == 18) {
      tmpProfile["whatPet"] = "cat";
    } else if ($answers.pets == 19) {
      tmpProfile["whatPet"] = "both";
    } else if ($answers.pets == 20) {
      tmpProfile["whatPet"] = "none";
    }

    tmpProfile["score"] =
      100 - Object.values($scores).reduce((a, b) => a + b) * scoringScaleFactor; // make sure this is inline with recommendation

    let products = getRecommendableProducts();
    tmpProfile["recommendations"] = products.map((r) => r.text) || [];
    tmpProfile["thirdPartyData"] = getRecommendationBlurb(products) || "";

    $profile = tmpProfile;

    await processUserFormData(tmpProfile);

    document.body.scrollTo(0, 0);
    // }
  }

  function getNextSection() {
    switch (sectionName) {
      case Section.Immunity:
        return Section.Everyday;
      case Section.Everyday:
        return Section.Pets;
      case Section.Pets:
        return Section.Pregnancy;
      case Section.Pregnancy:
        return Section.Stress;
      case Section.Stress:
        return Section.Move;
      case Section.Move:
        return Section.Sleep;
      case Section.Sleep:
        return Section.Fish;
      case Section.Fish:
        return Section.Final;
    }
  }

  function getPregnancyStatusFromChoice() {
    switch ($answers.pregnancy) {
      case 21:
        return "parent";
      case 22:
        return "none";
      case 23:
        return $profile.gender !== "male" ? "pregnant" : "none";
      case 24:
        return "conceive";
      case 44:
        return "none";
      default:
        return "none";
    }
  }

  function addSectionToProfile(section: Section): boolean {
    let currProfile = $profile;

    if (!isSectionUnlocked(currProfile, section)) {
      currProfile.sectionsUnlocked.push(section);
      $profile = currProfile;

      // wait a second
      setTimeout(() => {
        const nextsection = getNextSection();

        console.debug(`Section: ${nextsection}`);
        if (nextsection !== Section.Final) {
          document.getElementById("section-" + nextsection).scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        } else {
          setTimeout(() => {
            document.getElementById("section-recommendations").scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }, 400);
        }
      }, 200);

      return true;
    }
    // console.log("NA" + section);
    return false; // already has an entry
  }
</script>

<svelte:window bind:scrollY={y} />

<div
  id="section-{sectionName}"
  use:scrollRef={sectionName}
  use:inview={{}}
  on:change={(event) => {
    const { entry, inView } = event.detail;

    // console.log(inView )
    if (entry.isIntersecting) {
      // change masthead color
      // console.log(baseColor )
    }
  }}
/>

<div class="section-wrapper">
  <!-- text-8xl -->
  <SubSection
    class="{titleBgClass} relative intro flex flex-col py-52 overflow-hidden justify-center h-full "
    textclass="font-dmsans font-bold uppercase {titleTextClass} "
    windowY={y}
    questions={question.titles}
    triggerSectionSwap={true}
    {sectionName}
  >
    {#if question.posterImageUrl}
      <ScrollScaledImage posterImageUrl={question.posterImageUrl} />
    {/if}
    {#if stopMoItems && stopMoItems.length > 0}
      <StopMoCarousel items={stopMoItems} />
    {/if}
  </SubSection>

  <!-- text-7xl  -->
  <SubSection
    class=" {infoBgClass} description flex flex-col py-12 overflow-hidden justify-center relative pt-40"
    textclass="font-dmsans font-bold uppercase {infoTextClass} "
    windowY={y}
    questions={question.info}
    animateTextFromCenter
    {sectionName}
  >
    <div
      use:inview={{}}
      on:change={(event) => {
        const { inView } = event.detail;
        infoInView = inView;
      }}
      class="pt-28 flex flex-col  w-full justify-center relative z-10"
    >
      {#if infoInView}
        <span
          class=" {infoBgClass} {infoTextClass} text-center bg-transparent font-dmsans font-bold text-xl max-w-sm self-center p-8 z-20"
          in:fade={{ duration: 800 }}
          out:fade
        >
          {question.blurb}</span
        >
      {/if}
    </div>
    <div slot="lottie">
      <MediaQuery query="(min-width: 640px)" let:matches>
        {#if matches && question && question.lottieUrl}
          <div class="-z-0 w-full h-full pb-12">
            <Lottie src={question.lottieUrl} />
          </div>
        {/if}
      </MediaQuery>

      <MediaQuery query="(max-width: 640px)" let:matches>
        {#if matches && question && question?.lottieUrl}
          <div class="-z-0 w-full h-full pb-12">
            <Lottie src={question.lottieMobileUrl} />
          </div>
        {/if}
      </MediaQuery>
    </div>
  </SubSection>

  <section
    class="{infoBgClass}  question h-full py-44"
    use:inview={{}}
    on:change={(event) => {
      const { inView } = event.detail;
      questionInView = inView;
    }}
  >
    <!-- {#if questionInView} -->
    <div
      class="pt-12  mx-4 text-white font-dmsans flex flex-col items-center"
      in:fade
      out:fade
    >
      <p class="text-2xl font-dmsans font-bold mb-12 text-center">
        {$questions[sectionName].text}
      </p>
      <div class="max-w-xs w-full">
        {#if $questions}
          {#each $questions[sectionName].options as question}
            <!-- If no text is set then nothing gets displayed -->
            {#if $profile.gender === "male" && question.altTextMen && question.altTextMen.length > 0}
              <button
                style="--question--hover-color:{baseColor};"
                class={`p-4 hover:${questionHoverTextClass} questiontext font-dmsans font-medium w-full text-center border border-white rounded-lg my-4 hover:bg-white hover:text-gray-600 cursor-pointer 
                  ${
                    question.id === $answers[sectionName]
                      ? "bg-white " + questionHoverTextClass
                      : ""
                  }`}
                on:click|preventDefault|stopPropagation|nonpassive={() =>
                  triggerSelection(question.id, question.score)}
              >
                {question.altTextMen}
              </button>
            {:else if $profile.gender !== "male" && $profile.gender !== "female" && question.altTextNonSpecific && question.altTextNonSpecific.length > 0}
              <button
                style="--question--hover-color:{baseColor};"
                class={`p-4 hover:${questionHoverTextClass} questiontext font-dmsans font-medium w-full text-center border border-white rounded-lg my-4 hover:bg-white hover:text-gray-600 cursor-pointer 
                ${
                  question.id === $answers[sectionName]
                    ? "bg-white " + questionHoverTextClass
                    : ""
                }`}
                on:click|preventDefault|stopPropagation|nonpassive={() =>
                  triggerSelection(question.id, question.score)}
              >
                {question.altTextNonSpecific}
              </button>
            {:else if question.text && question.text.length > 0}
              <button
                style="--question--hover-color:{baseColor};"
                class={`p-4 hover:${questionHoverTextClass} questiontext font-dmsans font-medium w-full text-center border border-white rounded-lg my-4 hover:bg-white hover:text-gray-600 cursor-pointer 
                ${
                  question.id === $answers[sectionName]
                    ? "bg-white " + questionHoverTextClass
                    : ""
                }`}
                on:click|preventDefault|stopPropagation|nonpassive={() =>
                  triggerSelection(question.id, question.score)}
              >
                {question.text}
              </button>
            {/if}
          {/each}
        {/if}
      </div>
    </div>
    <!-- {/if} -->
  </section>
</div>

<style>
  .questiontext:hover {
    /* @apply var(--question--hover-color, 0); */
    color: var(--question--hover-color, 0);
  }
</style>
